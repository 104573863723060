import { Component, Mixins } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import BaseOtelMixin from '@/modules/cars/modules/open-telemetry/mixins/common/base-otel.mixin';
import { LOGTYPE } from '@/modules/open-telemetry/constants';
import FleetService, { FleetServiceS } from '../../fleet/fleet.service';

/**
 * Mixin for rates pages.
 * Open OTEL span on before component mount and end it, on finishing document loading
 * (it doesn't take in count time for rerender component after document update).
 */
@Component
export default class CarFleetPageOtelLogs extends Mixins(BaseOtelMixin) {
    @Inject(FleetServiceS) private fleetService!: FleetService;

    static readonly spanPrefix = LOGTYPE.LOADING;

    beforeMount() {
        this.startSpan(CarFleetPageOtelLogs.spanPrefix);
    }

    mounted() {
        this.addEvent('mounted', CarFleetPageOtelLogs.spanPrefix);
        this.fleetService.storeState.loading.whenLoadingFinished()
            .then(_ => {
                this.addEvent('gotRespnose', CarFleetPageOtelLogs.spanPrefix);
                this.endSpan(CarFleetPageOtelLogs.spanPrefix, { sendLogs: true, payload: this.preparePayload(this.fleetService.storeState.settings) });
            });
    }
}
