















import { Component, Mixins } from 'vue-property-decorator';
import CarFleetPageOtelLogs from '@/modules/cars/modules/open-telemetry/mixins/car-fleet-density-page-otel-log';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import FleetHeader from '@/modules/cars/modules/fleet/components/fleet-header.vue';
import FleetActions from '@/modules/cars/modules/fleet/components/fleet-actions.vue';
import FleetGraphSet from '@/modules/cars/modules/fleet/components/graph/fleet-graph-set.vue';

@Component({
    components: {
        PageWrapper,
        FleetHeader,
        FleetActions,
        FleetGraphSet,
    },
})
export default class FleetDensityPage extends Mixins(CarFleetPageOtelLogs) {}
