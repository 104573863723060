





















import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import GraphLegendIcon from '@/modules/common/components/ui-kit/graph-legend-icon.vue';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';
import PROVIDER_COLORS from '@/modules/common/constants/providers.colors.constant';
import GRAPH_COLORS, { DEFAULT_COLOR } from '@/modules/common/constants/default-graph-colors.constant';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';

export interface IFleetLabel {
    name: string | null;
    color: string;
    disabled: boolean;
}

@Component({
    components: {
        GraphLegendIcon,
    },
})
export default class FleetGraphBrokersLabel extends Vue {
    @Inject(HotelsServiceS) private hotelsService!: HotelsService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(FleetServiceS) private fleetService!: FleetService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    @Prop({
        type: Array,
        default: [],
        required: true,
    })
    disabled!: string[];

    @Prop({
        type: String,
        required: true,
    })
    provider!: string;

    get currentBroker(): IFleetLabel | null {
        const { currentCompany } = this.userService;

        return {
            name: currentCompany,
            color: CURRENT_HOTEL_GRAPH_COLOR,
            disabled: this.isCompetitorDisabled(currentCompany),
        };
    }

    get brokers(): IFleetLabel[] | null {
        return this.fleetService.getCompaniesByProvider(this.provider).map((broker, index) => ({
            name: broker,
            disabled: this.isCompetitorDisabled(broker),
            color: this.fleetService.getBrokersGraphColor(broker),
        })).filter(item => item.name !== this.userService.currentCompany)
            .filter(item => this.fleetService.getCompetitorsArrayFromGeneralSetting(this.provider).includes(item.name));
    }

    get getIconColor() {
        return this.userService.currentCompany ? PROVIDER_COLORS[this.userService.currentCompany] : DEFAULT_COLOR;
    }

    isCompetitorDisabled(competitor: string | null) {
        return competitor ? this.disabled.includes(competitor) : false;
    }

    toggleCompetitor(name: string | null) {
        if (!name) {
            return;
        }

        this.disabled = this.isCompetitorDisabled(name)
            ? this.disabled.filter((el: string) => el !== name)
            : this.disabled.concat(name);
        this.$emit('toggle', this.provider, this.disabled);
    }
    resetGraph() {
        this.disabled = [];
        this.$emit('toggle', this.provider, []);
    }
}
